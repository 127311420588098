
@import '../../styles/main.scss';

.btn {
    font-family: $font-lato;
    font-size: 16px;
    font-weight: bold;
    color: $white;
    line-height: 24px;
    text-transform: uppercase;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s;
    border-width: 2px;
    border-style: solid;

    &.btn-primary {
        background: $blue-3;
        border: 2px solid $blue-3;

        &:hover {
            background-color: $blue-5;
            border: 2px solid $blue-5;
        }
    }

    &.btn-outline {
        background: $white;
        color: $grey-2;
        border: 2px solid $grey-2;

        &:hover {
            color: $white;
            background: $grey-2;
        }
    }

    &.btn-text {
        color: $blue-3;
        border: transparent;

        &:hover {
            text-decoration: underline;
        }
    }
}
