@import '../../styles/main.scss';

.macaw-logo {
    width: 150px;
    height: 40px;

    img {
        width: 100%;
        height: 100%;
    }
}

.navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 13px 0;
    z-index: 1000;

    .container {
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
    }

    &.sticky {
        position: fixed;
        background: $grey-3;
        box-shadow: 0 2px 14px rgba(0, 129, 255, .2);
        transition: all .33s ease-in-out;
    }
}

.navbar-container {
    display: none;
    flex-direction: row;

    &.show {
        display: flex;
        position: absolute;
        top: 50px;
        right: 0;

        .navbar-nav {
            flex-direction: column;
            background: $white;
            border-radius: 4px;
            box-shadow: 0px 1px 10px 2px #e4e4e4;
            padding: 10px 5px;
        }

        .nav-link {
            text-transform: none;
        }
    }

    .btn-start-for-free {
        display: none;
        width: 185px;

        .btn {
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.navbar-nav {
    display: flex;
    list-style: none;
    margin-right: 18px;
    line-height: 42px;
    min-width: 150px;
}

.nav-item {
    padding: 0 21px;
    margin-left: 0;

    a {
        transform: none;
        text-decoration: none;
        color: black;
    }

    button:hover a {
        color: #1890FF;
    }

    &.active button{
        color: #1890FF;
        border-bottom: 2px solid #1890FF;
    }
}

.nav-link {
    font-family: $font-lato;
    font-weight: normal;
    font-size: 14px;
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
    background: transparent;
    border: none;
    padding-bottom: 5px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:hover {
        border-bottom: 2px solid transparent;
    }

    &.active {
        color: #1890FF;
        border-bottom: 2px solid #1890FF;
    }
}



.btn-hambuger {
    background: url('../../assets/images/hambuger.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    border: none;
    position: relative;
}

.nav-item.language {
    display: none;
}

@media screen and (min-width: 1200px) {
    .navbar {
        padding: 32px 0;

        .container {
            padding: 0;
        }

        &.sticky {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    .navbar-container {
        display: flex;

        .btn-start-for-free {
            display: flex;
        }

        &.show {
            display: flex;
            position: relative;
            top: 0;

            .nav-link {
                font-family: $font-lato;
                font-weight: bold;
                font-size: 14px;
                color: $black;
                text-decoration: none;
                text-transform: uppercase;
                background: transparent;
                border: none;
                padding-bottom: 5px;
                cursor: pointer;
                border-bottom: 2px solid transparent;

                &:hover {
                    color: $blue;
                    border-bottom: 2px solid $blue;
                }
            }

            .navbar-nav {
                flex-direction: row;
                position: unset;
                top: 80px;
                right: 20px;
                list-style: none;
                background: transparent;
                border-radius: 4px;
                box-shadow: none;
                padding: 0;
            }
        }
    }

    .nav-link {
        font-weight: bold;

        &:hover {
            color: $blue;
            border-bottom: 2px solid $blue;
        }
    }

    .nav-item {
        margin-left: 16px;
    }

    .nav-item.language {
        display: unset;
        text-align: center;
        position: relative;

        button {
            padding-bottom: 0
        }

        &:after {
            content: '|';
            color: #D9D9D9;
            position: absolute;
            right: -10px;
            font-size: 20px;
        }

        svg {
            // padding-right: 5px;
            position: absolute;
            top: 10px;
            left: -10px;
        }

        .btn-translate {
            font-family: $font-lato;
            font-weight: normal;
            font-size: 14px;
            color: black;
            text-decoration: none;
            text-transform: uppercase;
            background: transparent;
            border: none;
            padding-bottom: 5px;
            cursor: pointer;
            font-weight: bold;
        }
    }

    .navbar .dropdown {
        box-sizing: border-box;
        border-radius: 4px;
        outline: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
        padding: 10px;
        width: 150px;
        position: absolute !important;
        background: white;
        right: 0;
        display: none;

        &.showTrans {
            display: block;
        }

        button {
            font-family: $font-lato;
            display: block;
            padding: 10px 12px !important;
            overflow: hidden;
            cursor: pointer;
            font-size: 14px;
            transition: background .3s ease;
            width: 100%;
            border-radius: 4px;
            border: 0px;
            text-align: left;
            text-transform: uppercase;
            margin: 5px 0;
            background: transparent;

            &:hover {
                background: #e6f7ff;
            }
        }
    }
}