@import '../../styles/main.scss';

#pricing-home {
    text-align: center;
    width: 100%;
    display: block;
    padding: 144px 20px 70px;

    .slogan {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 144%;
        color: #262626;
        padding-bottom: 16px;
    }

    .desc {
        font-weight: normal;
        font-size: 18px;
        line-height: 156%;
        color: $grey;
        text-align: center;
        margin: 0 auto;
    }

    .option-pricing {
        font-family: $font-lato;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            padding: 0 26px;
            font-size: 14px;
            line-height: 22px;
            color: #595959;

            &.active {
                font-weight: bold;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    #pricing-home {
        padding-top: 144px;

        .slogan {
            padding-bottom: 8px;
            font-size: 40px;
            line-height: 48px;
        }

        .desc {
            font-size: 32px;
            line-height: 48px;
        }

        .option-pricing {
            p {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}