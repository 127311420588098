@import '../../styles/main.scss';

#question-section {
    font-family: $font-lato;
    padding: 100px 20px 0;
    display: block;

    .question-section_title {
        text-align: center;
        width: 100%;
        padding-bottom: 48px;

        h2 {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #262626;
            text-transform: uppercase;
        }
    }

    .question-section_body {
        padding-bottom: 10px;

        .asked_questions {

            h4,
            p {
                font-size: 16px;
                line-height: 24px;
                color: #595959;
            }

            h4 {
                font-weight: bold;
                padding-bottom: 28px;
                color: #262626;
            }

            .question {
                padding-bottom: 40px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    #question-section {
        padding: 138px 20px 0;

        .question-section_title {
            padding-bottom: 78px;

            h2 {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .question-section_body {
            padding-bottom: 57px;

            .asked_questions {
                display: flex;
                justify-content: space-between;

                .question {
                    max-width: 455px;
                }

                h4,
                p {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }

}