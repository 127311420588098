@import '../../styles/main.scss';

.section-features {
    display: block !important;
    padding: 96px 0 0;

    .section-content,
    .section-img {
        vertical-align: middle;
        margin: 0 25px;
    }

    .section-img {
        img {
            width: 100%;
        }
    }

    .section-content {
        position: relative;
        padding-top: 25px;
        margin-top: 40px;

        .section-title {
            font-family: $font-lato;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            color: $black-2;
        }

        .section-subtitle {
            padding-left: 17px;
            padding-top: 16px;
            font-family: $font-lato;
            font-size: 14px;
            line-height: 156%;
            color: $grey;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 90px;
            height: 6px;
            background: #0080FF;
            border-radius: 4px;
            z-index: 10;
        }

        .slick-slide img {
            width: 100%;
        }
    }


}

#one-page-workspace {
    .section-content:before {
        background: #36CFC9;
    }
}

#team-performance {
    padding-bottom: 50px;
    .section-content:before {
        background: #FFA940;
    }
}

#collaborate-effectively,
#theme {
    .section-content:before {
        background: #9254DE;
    }
}

#theme {
    padding-bottom: 41px;
    padding-top: 76px;

    .section-content:before {
        width: 60px;
    }
}

@media screen and (min-width: 1200px) {
    .section-features {
        padding: 50px 0;
        // justify-content: space-between;
        display: flex !important;
        flex-direction: row !important;
        align-items: center;

        .section-content,
        .section-img {
            vertical-align: middle;
            width: 45%;
            display: inline-block;
            margin: 0 25px;
        }

        .section-content {
            margin-top: 0;
            width: 468px !important;

            .section-title {
                font-size: 40px;
                line-height: 48px;
            }

            .section-subtitle {
                padding-left: 22px;
                padding-top: 24px;
                font-size: 20px;
                line-height: 156%;
            }

            &:before {
                width: 120px;
            }
        }

        &.section-reverse {
            flex-direction: row-reverse !important;

            .section-img {
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }

    #collaborate-effectively {
        .section-features .section-content {
            width: 488px !important;
        }

        .section-reverse {
            flex-direction: row-reverse;
            display: flex !important;
            align-items: center;

            .section-img {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }

    #team-performance {
        margin-bottom: 35px;

        .section-features .section-content {
            width: 500px !important;
            margin-left: 0 !important;
        }
    }

    #one-page-workspace.container,
    #team-performance.container,
    #epics.container {
        max-width: 100vw;

        .section-features {
            width: 100%;
        }

        .section-features .section-img {
            width: 55%;
            margin: 0;
        }

        .section-content {
            margin-left: 80px;
        }
    }

    #collaborate-effectively.container {
        .section-features {
            width: 100%;
        }

        .section-features .section-img {
            width: 55%;
            margin: 0;
        }

        .section-features .section-content {
            margin-left: 0;
        }
    }

    #theme {
        padding-bottom: 50px;
        padding-top: 0;

        .section {
            padding-bottom: 45px;
        }

        .section-features .section-img {
            width: 60%;
            margin: 0;
        }

        .section-features .section-content {
            margin: 0;
        }
    }
}