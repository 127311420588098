@import '../../styles/main.scss';

#contact_us-section,
#contact_us-section-homepage {
    font-family: $font-lato;
    background: #FAFAFA;
    padding: 63px 20px;
    text-align: center;

    .slogan {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        line-height: 144%;
        color: #262626;
    }

    .contact-us {
        background: #1890FF;
        border-radius: 4px;
        padding: 12px 32px;
        text-transform: uppercase;
        color: $white;
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
    }
}

#contact_us-section {
    .slogan {
        padding-bottom: 32px;
    }
}

#contact_us-section-homepage {
    .slogan {
        padding-bottom: 16px;
    }

    p {
        font-size: 14px;
        line-height: 156%;
        color: #595959;
        padding-bottom: 32px;
    }
}

@media screen and (min-width: 992px) {

    #contact_us-section,
    #contact_us-section-homepage {
        padding: 83px 20px;

        .slogan {
            font-size: 40px;
            line-height: 48px;
            padding-bottom: 56px;
        }

        .contact-us {
            line-height: 22px;
            padding: 14px 16px;
        }
    }

    #contact_us-section {
        .slogan {
            padding-bottom: 56px;
        }
    }

    #contact_us-section-homepage {
        .slogan {
            padding-bottom: 24px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 48px;
        }
    }
}