$white: white;
$black: black;
$black-2: #262626;
$black-3: #202E43;
$black-4: #334054;
$black-5: #1F2532;
$blue: #1890FF;
$blue-2: #6F92B5;
$blue-3: #0080FF;
$blue-4: #EBF6FF;
$blue-5: #0069d9;
$grey: #595959;
$grey-2: #739AC0;
$grey-3: #fbfbfb;
$highlight: #fafafa;