@import '../../styles/main.scss';

.section {
    // display: flex;
    // flex-direction: column;
    // max-width: 1140px;
    margin: 0 auto;
    background: #FAFAFA;
    padding: 63px 20px;
    text-align: center;

    .section-head {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 20px;

        .section-title {
            font-family: $font-lato;
            font-weight: 500;
            font-size: 30px;
            line-height: 144%;
            color: #262626;
            margin: 0;
        }

        .section-subtitle {
            font-family: $font-lato;
            font-size: 14px;
            line-height: 156%;
            font-weight: normal;
            color: $grey;
            text-align: center;
            padding: 16px 10px 0;
        }
    }

    .btn {
        background: #1890FF;
        border-radius: 4px;
        padding: 12px 32px;
        text-transform: uppercase;
        color: $white;
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        width: auto;
        border: 1px solid #1890FF;
        margin-top: 32px;
        margin-bottom: 20px;
    }

    .btn.btn-primary:hover {
        background-color: #0069d9;
        border: 1px solid #0069d9;
    }
}

@media screen and (min-width: 1200px) {
    .section {
        // display: flex;
        // flex-direction: column;
        // max-width: 1140px;
        margin: 0 auto;

        .section-head {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 70px;

            .section-title {
                font-size: 40px;
                line-height: 48px;
            }

            .section-subtitle {
                font-size: 20px;
                line-height: 24px;
                width: 650px;
                padding-top: 24px;
            }
        }

        .btn {
            line-height: 22px;
            padding: 14px 16px;
            margin-top: 48px;
            margin-bottom: 100px;
        }
    }
}