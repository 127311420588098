@import '../../styles/main.scss';


#theme {
    background: #1C1C1E;
    text-align: center;

    .slogan {
        color: #FFFFFF;
        opacity: 0.9;
    }
}

.section-slider {
    text-align: center;

    .section-title {
        font-family: $font-lato;
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
        color: $black-2;
    }

    .section-subtitle {
        font-family: $font-lato;
        font-size: 14px;
        line-height: 156%;
        color: $grey;
        padding-bottom: 56px;
        padding-top: 24px;
        margin: 0 auto;
    }
}

// Slider

.slick-slider {
    padding-top: 30px;
}

.slick-dots {
    bottom: 15px;
    top: unset;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 40px;

    li button:before {
        font-size: 20px !important;
    }

    li {
        margin: 0 15px !important;
    }

    li.slick-active button {
        width: 38px;
        height: 38px;
        background: #1C1C1E;
    }

    li button:before {
        opacity: 1;
    }

    li.slick-active {
        width: max-content;
        height: 38px;
    }

    li:nth-child(1) {
        &.slick-active {
            button {
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url('../../assets/images/active_blue.svg');

                &:before {
                    color: transparent !important;
                }
            }
        }

        button:before {
            color: #007AFF !important;
        }
    }

    li:nth-child(2) {
        &.slick-active {
            button {
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url('../../assets/images/active_orange.svg');

                &:before {
                    color: transparent !important;
                }
            }
        }

        button:before {
            color: #FF9500 !important;
        }
    }

    li:nth-child(3) {
        &.slick-active {
            button {
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url('../../assets/images/active_green.svg');

                &:before {
                    color: transparent !important;
                }
            }
        }

        button:before {
            color: #34C759 !important;
        }
    }
}

.slick-next,
.slick-prev {
    display: none !important;
}

#theme {
    .section {
        background-color: transparent;
        padding: 0;
    }

    .section-content:before {
        width: 60px;
        background: #9254DE;
    }

    .section-content {
        // width: 40%;
        // display: inline-block;
        text-align: left;
    }
}

.slider-img {
    outline-color: transparent;
    outline-style: none;
    padding-bottom: 50px;

    img {
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {
    #theme {
        .slogan {
            padding-top: 72px;
            width: 630px;
            font-weight: bold;
            font-size: 40px;
            line-height: 56px;
        }

        .section {
            max-width: 1140px;
            display: flex;
            background-color: transparent;
            align-items: center;
            padding: 0;
            flex-direction: row-reverse !important;
        }

        .slick-slider {
            outline-style: none;
            display: inline-block;
            width: 100%;
            padding-top: 30px;
        }

        .section-content {
            width: 40%;
            display: inline-block;
            text-align: left;
            padding: 0;
            padding-top: 25px;
            margin-left: -20px;
        }
    }
}


@media screen and (max-width: 767px) { 
    .slick-dots {
        bottom: 0px;
    }
}