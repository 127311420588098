// @import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900&display=swap&subset=latin-ext);

@font-face {
    font-family: 'MyWebFont';
    font-display: swap;
    src: url('../assets/fonts/fontLato/LATO-MEDIUM.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-BLACK.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-BLACKITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-BOLD.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-BOLDITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-HAIRLINE.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-HAIRLINEITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-HEAVY.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-HEAVYITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-ITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-LIGHT.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-LIGHTITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-MEDIUMITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-REGULAR.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-SEMIBOLD.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-SEMIBOLDITALIC.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-THIN.TTF') format('truetype'),
        url('../assets/fonts/fontLato/LATO-THINITALIC.TTF') format('truetype')
}

@font-face {
    font-family: 'slick';
    src: url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/fonts/slick.woff) format('woff');
    font-display: swap;
}

$font-lato: 'MyWebFont';