
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    outline: none;
}

.visible-desktop {
    display: none;
}

.visible-mobile {
    display: block;
}


.wrapper {
    position: relative;
}

.container {
    display: flex;
    margin: 0 auto;
}

@media (min-width: 1200px) {

    .container {
        max-width: 1140px;
    }

    .visible-desktop {
        display: flex;
    }

    .visible-mobile {
        display: none !important;
    }

    .slick-dots {
        top: unset;
    }
}