@import '../../styles/main.scss';

.footer {
    display: flex;
    background: $black-3;
    padding: 65px 20px 0 20px;
    height: 260px;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .language {
        display: unset;
        text-align: center;
        position: relative;

        button {
            padding-bottom: 0
        }

        svg {
            position: absolute;
            top: -3px;
            left: -25px;
        }

        .icon-arrow {
            display: inline-block;
            margin-left: 10px;

            svg {
                position: relative;
                top: -2px;
                left: auto;
            }
        }

        .btn-translate {
            font-family: $font-lato;
            font-weight: normal;
            font-size: 14px;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            background: transparent;
            border: none;
            padding: 0 5px 20px;
            cursor: pointer;
            font-weight: bold;
            position: relative;
        }

        .dropdown {
            box-sizing: border-box;
            border-radius: 4px;
            outline: none;
            box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
            padding: 10px;
            width: 150px;
            position: absolute !important;
            background: white;
            // right: 0;
            display: none;

            &.showTrans {
                display: block;
            }

            button {
                font-family: $font-lato;
                display: block;
                padding: 10px 12px !important;
                overflow: hidden;
                cursor: pointer;
                font-size: 14px;
                transition: background .3s ease;
                width: 100%;
                border-radius: 4px;
                border: 0px;
                text-align: left;
                text-transform: uppercase;
                margin: 5px 0;
                background: transparent;

                &:hover {
                    background: #e6f7ff;
                }
            }
        }
    }

    .policy {
        color: #FFF;
        font-family: $font-lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;
        display: inline;
        cursor: pointer;

        &::after {
            content: '|';
            padding: 0 8px; 
        }
    }
}

.copyright {
    font-family: $font-lato;
    font-size: 14px;
    color: $blue-2;
    line-height: 22px;
}

.contact {
    font-family: $font-lato;
    font-size: 14px;
    color: $white;
    line-height: 22px;
    text-decoration: none;
}

.social {
    margin-top: 16px;

    .item-appstore {
        margin-right: 50px;
        display: none;
    }

    .item-social {
        &:last-child {
            margin-left: 20px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .footer {
        padding: 0;
        height: 160px;

        .container {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .language {
            display: none;
        }
    }

    .social {
        margin-top: 0;

        .item-appstore {
            display: unset;
        }
    }
}