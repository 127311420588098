@import '../../styles/main.scss';

#new-pricing-section {
    font-family: $font-lato;
    padding-top: 57px;
    margin: 0 16px;
}

.pricing-option-table {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    border-spacing: 0;

    .title-option {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: $grey;
        text-align: center;
        padding-bottom: 38px;
    }

    .option-detail-description {
        min-width: 110px;
        font-size: 14px;
        line-height: 22px;
        color: #262626;
        padding: 14px 0;
    }

    .option-detail {
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        padding: 14px 20px;
        color: $black-2;
        font-weight: bold;

        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: $blue;
        }

        &.pricing {
            color: $grey;
            font-weight: normal;
        }

        &.last-column {
            padding-bottom: 56px;
            border-radius: 0 0 4px 4px;
        }

        a {
            color: #1890FF;
        }
    }

    .first-column {
        padding-top: 24px;
        border-radius: 4px 4px 0 0;

        img {
            width: 100%;
        }
    }

    .team-option {
        background: $highlight;
    }
}

// @media screen and (min-width: 1200px) {
@media screen and (min-width: 992px) {
        #new-pricing-section {
            margin: 0 auto;
        }

        .pricing-option-table {
            img {
                width: auto;
            }

            .title-option {
                font-size: 32px;
                line-height: 48px;
            }

            .option-detail {
                font-size: 20px;
                line-height: 28px;
                max-width: 265px;
                width: 265px;

                span {
                    font-size: 40px;
                    line-height: 48px;
                }
            }

            .option-detail-description {
                font-size: 20px;
                line-height: 28px;
                padding-left: 14px 0 14px 30px;
            }
        }

        .w-100 {
            width: unset;
        }

        .first-column {
            img {
                width: auto;
            }
        }
    }