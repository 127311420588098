@import '../../styles/main.scss';

#home {
    padding-top: 122px;

    &.container {
        display: block;
    }

    .hero-section {
        display: block;
        text-align: left;
        padding: 0 20px;
    }

    .video-intro {
        position: relative;

        img {
            width: 100%;
            margin: 0 auto;
            padding-bottom: 32px;
        }
    }

    .animation {
        border: 0;
        font-family: $font-lato;
        font-weight: 800;
        font-size: 30px;
        line-height: 144%;
        color: $blue;
        display: inline-block;
        width: 200px;
    }

    .animation::-webkit-input-placeholder {
        color: $blue;
    }

    .animation:-ms-input-placeholder {
        color: $blue;
    }

    .animation::placeholder {
        color: $blue;
    }
}

.slogan {
    font-family: $font-lato;
    font-weight: 800;
    font-size: 30px;
    line-height: 144%;
    color: #1F2532;

    strong {
        color: $blue;
        display: inline-block;
    }
}

.start-planning-container {
    width: 200px;
}

.desc {
    font-family: $font-lato;
    font-size: 14px;
    line-height: 156%;
    color: $grey;
    padding-bottom: 31px;
    letter-spacing: 0.1px;
}

.discount {
    display: block;
    font-family: $font-lato;
    font-size: 16px;
    line-height: 19px;
    color: $grey;
    margin-top: 12px;
}

// .block-left {
//     max-width: 100%;
//     padding: 0 20px;
//     position: relative;

//     .scroll-block {
//         display: none;
//         position: absolute;
//         left: 0;
//         bottom: 50px;

//         p {
//             width: 86px;
//             font-family: $font-lato;
//             font-weight: 700;
//             font-size: 12px;
//             color: #595959;
//             text-transform: uppercase;
//             padding-top: 16px;
//         }
//     }
// }

// .block-right {
//     display: none;
// }

@media screen and (min-width: 1200px) {
    #home {
        padding-top: 160px;
        padding-bottom: 140px;

        &.container {
            display: flex;
            flex-direction: row-reverse !important;
            align-items: center;
            justify-content: space-between;
        }

        .hero-section {
            padding: 0;
            // width: 103%;
            // margin-right: -60px;

            h2 {
                width: 114%;
                margin-right: -60px;
            }
        }

        .video-intro {
            right: -30px;

            img {
                padding-bottom: 0;
                width: 585px;
            }
        }

        .animation {
            font-size: 38px;
            font-weight: 800;
            line-height: 63px;
        }
    }

    .slogan {
        font-size: 38px;
        font-weight: 800;
        line-height: 63px;
        padding-bottom: 34px;
        margin: auto;

        strong {
            display: inline-block;
        }
    }

    .desc {
        width: 430px;
        font-size: 16px;
        line-height: 25px;
        padding-bottom: 46px;
    }

    .discount {
        display: none;
    }

    // .block-left {
    //     max-width: 50%;
    //     padding: 77px 0 0 0;

    //     .scroll-block {
    //         display: flex;
    //         margin-left: -18px;
    //     }
    // }

    // .block-right {
    //     max-width: 50%;
    //     margin-left: -71px;
    //     display: block;
    // }
}