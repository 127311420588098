@import '../../styles/main.scss';

.policy-page {
    &__header {
        max-width: 1118px;
        width: 1118px;
        margin: 0 auto;
        padding: 20px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .macaw-logo {
            padding-left: 12px;
            cursor: pointer;
        }
    }

    &__body {
        width: 1118px;
        margin: 56px auto 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &--title {
            color: var(--Secondary, #1F2532);
            font-family: $font-lato;
            font-size: 38px;
            font-style: normal;
            font-weight: 800;
            line-height: 144%;
            margin-bottom: 32px;
        }

        &--section {
            margin-bottom: 64px;

            &:last-child {
                margin-bottom: 56px;
            }

            &__header {
                color: var(--Grey-grey-9, #262626);
                font-family: $font-lato;
                font-size: 34px;
                font-weight: 800;
                line-height: 130%;
                margin-bottom: 16px;
            }

            &__title {
                color: #000;
                font-family: $font-lato;
                font-size: 22px;
                font-style: normal;
                font-weight: 800;
                line-height: 150%;
                margin-bottom: 8px;
            }

            &__content {
                color: #000;
                font-family: $font-lato;
                font-size: 18px;
                line-height: 150%;
                margin-bottom: 32px;
                font-weight: 400;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            
            .strong{
                font-weight: 700;
            }


        }

        .mb-24 {
            margin-bottom: 24px;
        }
    }

    &__date-revision {
        color: #000;
        font-family: $font-lato;
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        line-height: 150%;
        max-width: 1118px;
        width: 1118px;
        margin: 0 auto 56px;
    }
}

@media (max-width: 1366px) {
    .policy-page {
        &__header,
        &__body,
        &__date-revision {
            max-width: 1118px;
            width: unset;
            margin-left: 124px;
            margin-right: 124px;
        }
    }
}

@media (max-width: 768px) {
    .policy-page {
        &__header,
        &__body,
        &__date-revision {
            margin-left: 32px;
            margin-right: 32px;
        }
    }
}